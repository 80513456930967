<template>
    <div class="container">
        <el-card>
            <div class="update_head">系统更新</div>
            <el-form :model="form" :rules="rules" ref="ruleForm" label-width="80px">
                <el-form-item label="反馈原因" prop="type" v-if="Object.keys(noLoginGlobal).length">
                    <el-select v-model="form.type" placeholder="请选择反馈原因">
                        <el-option :label="item.value" :value="item.key"
                            v-for="item in noLoginGlobal['cloud.data']['feedback_type']" :key="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="反馈内容" prop="content">
                    <el-input type="textarea" v-model="form.content" :autosize="{ minRows: 4, maxRows: 10 }"></el-input>
                </el-form-item>
                <el-form-item v-if="hasPerm(['cloud.feedback.add'])">
                    <el-button type="primary" @click="submitForm('ruleForm')" :loading="submitLoading">提交</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    data() {
        return {
            form: {
                type: '',
                content: '',
            },
            rules: {
                type: [
                    { required: true, message: '请选择反馈原因', trigger: 'change' }
                ],
                content: [
                    { required: true, message: '请输入反馈内容', trigger: 'change' },
                ],
            },
            submitLoading: false
        };
    },
    computed: {
        ...mapState(['noLoginGlobal'])
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // 整理数据
                    this.submitLoading = true

                    let _data = {
                        type: this.form.type + '',
                        content: this.form.content
                    }
                    console.log(_data, '_data');
                    this.$post(this.$apis.setFeedback, _data).then(res => {
                        console.log(res, 'setFeedback');
                        if (res.code == 200) {
                            this.resetForm()
                            this.$message.success(res.message)
                            this.submitLoading = false
                        } else {
                            this.$message.error(res.message)
                        }
                    })

                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        resetForm() {
            this.$refs['ruleForm'].resetFields();
        }
    }
};
</script>
<style lang="scss" scoped>
.update_head {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    padding-bottom: 10px;
    border-bottom: solid 1px #ECEEEF;
    margin-bottom: 16px;
}
</style>